import './styles/EmptyTab.css'

function EmptyTab() {

    return (
        <div className="Emptytab-image-container">
            <img src={require('../Assets/EmptySign.png')} className="Emptytab-image" />
        </div>
    )

}

export default EmptyTab;