export class Attendee {

    name: string
    firebaseUserID: string
    
    constructor(name: string, firebaseUserID: string) {

        this.name = name
        this.firebaseUserID = firebaseUserID

    }

}