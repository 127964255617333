import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Snackbar, TextField } from "@mui/material";
import { Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText } from "@mui/material";
import { auth } from '../Firebase/firebase';
import { onAuthStateChanged } from "firebase/auth";
import { getUser, updateUser } from "../Services/UserService";
import { TextFieldInput } from "../Shared/styles/TextField";
import { User } from "../Models/User";
import './styles/Profile.css'

const Profile = () => {

    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [user, setUser] = useState<User | null>(null)
    const [deleteAccOpen, setDeleteAccOpen] = useState<boolean>(false)
    const [saveAccOpen, setSaveAccOpen] = useState<boolean>(false)

    const navigate = useNavigate()
    const routeToHomePage = () => {
        navigate('/');
    }

    useEffect(() => {
        onAuthStateChanged(auth, () => {
            FetchUserData()
        })
    }, [])

    const FetchUserData = async () => {

        if (auth.currentUser) {
            const userData = await getUser(auth.currentUser.uid)
            if (userData !== null) {
                setUser(userData)
                setFirstName(userData.firstName)
                setLastName(userData.lastName)
                setEmail(userData.email)
            }
        }
    }

    const SaveProfileClicked = async () => {

        if(firstName.length > 50 || lastName.length > 50) {
            return;
        }

        var updatedUser = new User(firstName, lastName, email)

        if (auth.currentUser) {
            await updateUser(auth.currentUser?.uid, updatedUser)
            setSaveAccOpen(true)
            FetchUserData()
        }
    }

    const ConfirmDeleteAccount = () => {

        setDeleteAccOpen(false)
        routeToHomePage()

    }

    return (
        <div className='Profile-container'>
            <Grid container direction={"column"} spacing={5}>
                <Grid item className='Createcottage-form-title'>
                    <p>Your Profile</p>
                </Grid>
                <Grid item>
                    <TextField 
                        sx={TextFieldInput} 
                        inputProps={{autoComplete: 'off', maxLength: '50'}} 
                        InputLabelProps={{ shrink: firstName !== '' ? true : false, style: { fontFamily: 'monospace', color: '#73925B' }}}
                        label="First Name"
                        name='profileFirstName'
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        
                    />
                </Grid>
                <Grid item>
                    <TextField 
                        sx={TextFieldInput} 
                        inputProps={{autoComplete: 'off', maxLength: '50'}} 
                        InputLabelProps={{ shrink: lastName !== '' ? true : false, style: { fontFamily: 'monospace', color: '#73925B' }}}
                        label="Last Name"
                        name='profileLastName'
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <TextField 
                        sx={TextFieldInput} 
                        inputProps={{autoComplete: 'off'}} 
                        InputLabelProps={{ shrink: email !== '' ? true : false, style: { fontFamily: 'monospace', color: '#73925B' }}}
                        label="Email"
                        name='profileEmail'
                        value={email}
                        disabled={true}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <div style={{ paddingBottom: 5 }}>
                        <Button
                            variant="contained"
                            sx={{
                                width: 300,
                                backgroundColor: '#1A3B0E',
                                '&:hover': {
                                    backgroundColor: '#102408'
                                }
                            }}
                            disabled={firstName === user?.firstName && lastName === user?.lastName}
                            onClick={() => SaveProfileClicked()}>
                            Save
                        </Button>
                    </div>
                    {/* <div style={{ paddingTop: 5 }}>
                        <Button
                            variant="contained"
                            sx={{ 
                                width: 300,
                                backgroundColor: '#7d1e23',
                                '&:hover': {
                                    backgroundColor: '#571518'
                                }
                             }}
                             onClick={() => setDeleteAccOpen(true)}>
                            Delete Profile
                        </Button>
                    </div> */}
                </Grid>
            </Grid>
            <Dialog
                open={deleteAccOpen}
                onClose={() => setDeleteAccOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                    {"Do you want to delete you account?"}
                    </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        If you confirm then your profile and all information associated 
                        with Cottage Buddy will be removed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteAccOpen(false)}>No</Button>
                    <Button onClick={() => ConfirmDeleteAccount()}>Yes</Button>
                </DialogActions>
            </Dialog>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={saveAccOpen}
                    onClose={() => setSaveAccOpen(false)}
                    message="Profile Saved."
                    autoHideDuration={5000}
                />
        </div>
    )
}

export default Profile
