import './styles/CottageBuddyFooter.css'
import { useNavigate } from 'react-router-dom';

function CottageBuddyFooter() {


  //Navigation logic
  const navigate = useNavigate()  
  const routeToAbout = () => {
    navigate('/about');
    window.scrollTo(0, 0);
  };
  const routeToContactUs = () => {
    navigate('/contact');
    window.scrollTo(0, 0);
  };

    return (
        <div style={{ lineHeight: 1.5 }}>
            <div className='Footer-section'>Support</div>
            <div className='Footer-item' onClick={routeToAbout}>About Us</div>
            <div className='Footer-item' onClick={routeToContactUs}>Contact Us</div>
            <div className='Footer-bar' />
            <div style={{ alignItems: 'center' }}>&copy; 2024 Philip D'Aloia</div>
        </div>
    );

}

export default CottageBuddyFooter;