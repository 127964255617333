import { db } from '../Firebase/firebase';
import { doc, getDoc, setDoc, deleteDoc, updateDoc, DocumentReference, DocumentData, arrayUnion, arrayRemove, collection } from "firebase/firestore";
import { Attendee } from '../Models/Attendee';
import { User } from '../Models/User';
import { CottageInfo } from '../Models/CottageInfo';

async function checkForUserDocument(userId: string) : Promise<Boolean> {

    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return true;
    } else {
        // docSnap.data() will be undefined in this case
        return false;
    }

}

async function checkForUncreatedUserDoc(email: string) : Promise<String[]> {

    const uncreatedUserDoc = doc(db, "uncreated", email);
    const uncreatedUserDocSnap = await getDoc(uncreatedUserDoc);

    var pendingInvites;
    if (uncreatedUserDocSnap.exists()) {
        pendingInvites = uncreatedUserDocSnap.data().pendingInvites;
        await deleteDoc(uncreatedUserDoc);
    } else {
        pendingInvites = [];
    }

    return pendingInvites;

}

async function createUserDocument(userId: string, email: string, fullName: string) {

    //check for an uncreated user account doc
    checkForUncreatedUserDoc(email)
    .then(async (invitedCottages) => {
        //create the doc
        let displayNameWords = fullName.split(" ");
        await setDoc(doc(db, "users", userId), {
            cottageIDs: [],
            invitedCottageIDs: invitedCottages,
            email: email,
            firstName: displayNameWords[0],
            lastName: displayNameWords[displayNameWords.length - 1],
            fullName: fullName
        });
    });

}

async function getInvitedCottages(userId: string) : Promise<CottageInfo[]> {

    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);

    var invitedCottagesIds: string[] = [];
    if (docSnap.exists()) {
        invitedCottagesIds = docSnap.data().invitedCottageIDs;
    } else {
        invitedCottagesIds = [];
    }

    var cottagesToReturn: CottageInfo[] = [];
    for (const cottageId of invitedCottagesIds) {

        const cottageRef = doc(db, "cottages", cottageId);
        const cottageSnapshot = await getDoc(cottageRef);
        
        if (cottageSnapshot.exists()) {

            let cottageName = cottageSnapshot.data().tripName;
            let address = cottageSnapshot.data().address;
            let startDate = cottageSnapshot.data().startDate;
            let endDate = cottageSnapshot.data().endDate;
            let collaborativeGroceries = cottageSnapshot.data().collaborativeGroceries;
            let collaborativePosts = cottageSnapshot.data().collaborativePosts;

            let organizerDocRef: DocumentReference<DocumentData, DocumentData> = cottageSnapshot.data().organizer;
            let organizerSnapshot = await getDoc(organizerDocRef);

            let organizer = new Attendee("", "");
            if(organizerSnapshot.exists()) {
                organizer.name = organizerSnapshot.data().fullName;
                organizer.firebaseUserID = organizerSnapshot.id;
                let cottageInfo = new CottageInfo(cottageId, cottageName, organizer, startDate.toDate(), endDate.toDate(), collaborativeGroceries, collaborativePosts, address);
                cottagesToReturn.push(cottageInfo);
            } 

        }
        
    };

    return cottagesToReturn;
}

async function getAttendee(userReference: DocumentReference) : Promise<Attendee | null> {

    const docSnap = await getDoc(userReference);
    if (docSnap.exists()) {
        let fullName = docSnap.data().fullName;
        let userId = docSnap.id;
        return new Attendee(fullName, userId);
    }
    else {
        return null;
    }

}

async function getAttendeeById(userId: string) : Promise<Attendee | null> {

    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
        let fullName = docSnap.data().fullName;
        let userId = docSnap.id;
        return new Attendee(fullName, userId);
    } else {
        return null;
    } 

}

async function getUser(userId: string) : Promise<User | null> {

    const docRef = doc(db, "users", userId)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
        let firstName = docSnap.data().firstName
        let lastName = docSnap.data().lastName
        let email = docSnap.data().email
        return new User(firstName, lastName, email)
    }

    return null

}

async function updateUser(userId: string, user: User) {

    const userDoc = doc(db, "users", userId)
    await updateDoc(userDoc, {
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: user.firstName + ' ' + user.lastName
    })

    return null

}

async function acceptCottageInvite(cottageId: string, userId: string): Promise<null> {
    const userDocRef = doc(db, "users", userId)
    await updateDoc(userDocRef, {
        "cottageIDs": arrayUnion(cottageId),
        "invitedCottageIDs": arrayRemove(cottageId)
    });

    const attendeesCollectionRef = collection(db, "cottages", cottageId, "attendees");
    const userAttendeeDocRef = doc(attendeesCollectionRef, userId);

    await setDoc(userAttendeeDocRef, {
        "userDoc": userDocRef
    });

    const cottageDocRef = doc(db, "cottages", cottageId);
    await updateDoc(cottageDocRef, {
        "invitedEmails": arrayRemove(userId)
    });

    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
        let email = userDocSnap.data().email
        await updateDoc(cottageDocRef, {
            "invitedEmails": arrayRemove(email)
        });
    }

    return null;
}

async function declineCottageInvite(cottageId: string, userId: string): Promise<null> {
    const userDocRef = doc(db, "users", userId)
    await updateDoc(userDocRef, {
        "invitedCottageIDs": arrayRemove(cottageId)
    });

    const cottageDocRef = doc(db, "cottages", cottageId);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
        let email = userDocSnap.data().email
        await updateDoc(cottageDocRef, {
            "invitedEmails": arrayRemove(email)
        });
    }

    return null;
}

export { checkForUserDocument, createUserDocument, getInvitedCottages, getAttendee, getAttendeeById, getUser, updateUser, acceptCottageInvite, declineCottageInvite };
