import { Attendee } from "./Attendee"

export class CottageInfo {

    cottageID: string
    cottageName: string
    cottageOrganizer: Attendee
    startDate: Date
    endDate: Date
    isGroceriesCollaborative: boolean
    isPostsCollaborative: boolean
    address: string

    constructor(cottageId: string,
        cottageName: string, 
        cottageOrganizer: Attendee, 
        startDate: Date, 
        endDate: Date, 
        isGroceriesCollaborative: boolean, 
        isPostsCollaborative: boolean, 
        address: string) {

        this.cottageID = cottageId
        this.cottageName = cottageName
        this.cottageOrganizer = cottageOrganizer
        this.startDate = startDate
        this.endDate = endDate
        this.isGroceriesCollaborative = isGroceriesCollaborative
        this.isPostsCollaborative = isPostsCollaborative
        this.address = address
        
    }

}