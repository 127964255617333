import { DocumentReference, addDoc, collection, deleteDoc, doc, getDoc, getDocs } from "firebase/firestore";
import { ListItem } from "../Models/ListItem";
import { db } from "../Firebase/firebase";
import { getAttendee } from "./UserService";

function getLists(cottageId: string) : Promise<ListItem[]> {

    var list: ListItem[] = [];

    return getDocs(collection(db, "cottages", cottageId, "groceries"))
    .then((listsSnapshot) => {
        var promises: any[] = [];
        listsSnapshot.forEach((doc) => {
            let itemId: string = doc.id;
            let itemName: string = doc.data().productName;
            let itemQty: number = doc.data().quantity;
            let documentReference: DocumentReference = doc.data().assignedTo;
            if (documentReference) {
                promises.push(
                    getAttendee(documentReference)
                    .then((attendee) => {   
                        let item = new ListItem(itemId, itemName, itemQty, attendee);
                        return item;
                    })
                )
            }
            else {
                promises.push(
                    new Promise((resolve, _) => {
                        let item = new ListItem(itemId, itemName, itemQty, null);
                        resolve(item);
                    })
                )
            }
        });
        return Promise.all(promises);
    });

}

function uploadListItems(items: ListItem[], cottageId: string) : Promise<DocumentReference[]> {

    const listsCollection = collection(db, "cottages", cottageId, "groceries")

    var results: any[] = [];
    items.forEach((item) => {
        if (item.assignedTo) {
            let assignedUserDoc = doc(db, "users", item.assignedTo.firebaseUserID);
            results.push(
                addDoc(listsCollection, {
                    "productName" : item.productName,
                    "quantity" : item.quantity,
                    "assignedTo" : assignedUserDoc
                })
            );
        }
        else {
            results.push(
                addDoc(listsCollection, {
                    "productName" : item.productName,
                    "quantity" : item.quantity
                })
            )
        }
    });

    return Promise.all(results);

}

function deleteItemsFromFirestore(itemIds: readonly string[], cottageId: string) : Promise<void[]> {

    var results: any[] = [];
    itemIds.forEach((itemId) => {
        results.push(
            deleteDoc(doc(db, "cottages", cottageId, "groceries", itemId))
        );
    });
    return Promise.all(results);

}

export { getLists, uploadListItems, deleteItemsFromFirestore };