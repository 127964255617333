import './styles/Home.css';
import About from './About';

function Home() {
    return (
        <div className="Homepage-container">
            
            <h1 className="Homepage-title">
                Welcome to Cottage Buddy
            </h1>
            <div className='Homepage-abount-section-container'>
                <About/>
            </div>
        </div>
    );
}

export default Home;