import { Attendee } from "./Attendee";

export class ListItem {

    id: string
    productName: string
    quantity: number
    assignedTo: Attendee | null

    constructor (id: string,
        productName: string,
        quantity: number,
        assignedTo: Attendee | null
    ) {
        this.id = id;
        this.productName = productName;
        this.quantity = quantity;
        this.assignedTo = assignedTo;
    }

}