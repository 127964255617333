import { BorderColor, Padding } from "@mui/icons-material";

export const SelectFieldInput = {
    background: '#B1D291',
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: '#1A3B0E',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#1A3B0E',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#1A3B0E',
      },
    }

