import { addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, DocumentData, DocumentReference, getDocs, setDoc, updateDoc } from "firebase/firestore";
import { Car } from "../Models/Car";
import { db } from "../Firebase/firebase";
import { getAttendee, getAttendeeById } from "./UserService";

function getCars(cottageId: string) : Promise<Car[]> {

    return getDocs(collection(db, "cottages", cottageId, "cars"))
    .then((carsSnapshot) => {
        var promises: any[] = [];
        carsSnapshot.forEach((docSnap) => {
            let driverId: string = docSnap.id;
            let driverDocReference = doc(db, "users", driverId);
            let numberOfSeats: Number = docSnap.data().numberOfSeats ?? 0;
            let passengerIds: string[] = docSnap.data().passengers ?? [];
            let requesterIds: string[] = docSnap.data().requests ?? [];
            if (driverDocReference) {
                promises.push(
                    getAttendee(driverDocReference)
                    .then((attendee) => {   
                        if (attendee) {
                            let item = new Car(attendee, numberOfSeats, passengerIds, requesterIds)
                            return item;
                        }
                    })
                )
            }
        });
        return Promise.all(promises)
        .then((cars) => {
            return cars.filter(function(car){ return car !== undefined });
        });
    })

}

function addCarToFirestore(cottageId: string, driverId: string, numberOfSeats: number): Promise<void> {

    return setDoc(doc(db, "cottages", cottageId, "cars", driverId), {
        "numberOfSeats": numberOfSeats,
        "passengers": [] as string[],
        "requests": [] as string[]
    })
    
}

function deleteCarFromFirestore(cottageId: string, driverId: string): Promise<void> {

    return deleteDoc(doc(db, "cottages", cottageId, "cars", driverId));

}

function createPassengerRequest(cottageId: string, carDriverId: string, requesterId: string): Promise<void> {

    const docRef = doc(db, "cottages", cottageId, "cars", carDriverId)

    return updateDoc(docRef, {
        "requests": arrayUnion(requesterId)
    });

}

function removeAllCarRequestAndPassengerStatus(cottageId: string, requesterId: string): Promise<void[]> {

    return getDocs(collection(db, "cottages", cottageId, "cars"))
    .then((carsSnapshot) => {
        var promises: any[] = [];
        carsSnapshot.forEach((docSnap) => {
            const docRef = docSnap.ref;
            promises.push(
                updateDoc(docRef, {
                    "requests": arrayRemove(requesterId),
                    "passengers": arrayRemove(requesterId)
                })
            );
        });
        return Promise.all(promises);
    })

}

function leaveCarInFirestore(cottageId: string, carDriverId: string, passengerId: string): Promise<void> {

    const docRef = doc(db, "cottages", cottageId, "cars", carDriverId);

    return updateDoc(docRef, {
        "passengers": arrayRemove(passengerId)
    });

}

async function acceptPassengerRequestInFirestore(cottageId: string, carDriverId: string, passengerId: string): Promise<null> {

    const docRef = doc(db, "cottages", cottageId, "cars", carDriverId);

    //add as a passenger
    await updateDoc(docRef, {
        "passengers": arrayUnion(passengerId)
    })
    
    //remove all passengers
    var promises: any[] = [];
    await getDocs(collection(db, "cottages", cottageId, "cars"))
    .then((carsSnapshot) => {
        carsSnapshot.forEach((docSnap) => {
            const docRef = docSnap.ref;
            promises.push(
                updateDoc(docRef, {
                    "requests": arrayRemove(passengerId)
                })
            );
        });
    })

    await Promise.all(promises);

    return null;

}

async function declinePassengerRequestInFirestore(cottageId: string, carDriverId: string, passengerId: string): Promise<null> {

    const docRef = doc(db, "cottages", cottageId, "cars", carDriverId);

    await updateDoc(docRef, {
        "requests": arrayRemove(passengerId)
    });
    
    return null;

}

export { getCars, addCarToFirestore, deleteCarFromFirestore, createPassengerRequest, removeAllCarRequestAndPassengerStatus, leaveCarInFirestore, acceptPassengerRequestInFirestore, declinePassengerRequestInFirestore };