import { Cabin } from '@mui/icons-material'
import { useState } from 'react'

import './styles/CottageTile.css'

const CottageTile = ({
  name,
  address,
  startDate
} : {
  name: string,
  address: string,
  startDate: Date
}) => {

  const [hover, setHover] = useState(false)

  return (
   
    <table 
      className='Tile-table'
      style={{ backgroundColor: hover ? '#A7D489' : undefined }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <tbody>
        <tr>
          <td style= {{ width: '15%' }}>
            <Cabin fontSize='large' />
          </td>
          <td>
            <div className='Tile-name'>{name}</div>
            <div className='Tile-address'>{address}</div>
            <div className='Tile-date'>{new Date(startDate).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}</div>
          </td>
        </tr>
      </tbody>
    </table>
  )

}

export default CottageTile
