import './styles/Cottage.css'
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../Firebase/firebase';
import { CottageInfo } from '../Models/CottageInfo';
import { createPendingInvites, deleteCottageFromFirestore, deleteInviteFromFirestore, editCottageInfo, getAttendeesForCottage, getCottageInfo, getInvitedEmails, removeAttendeeFromFirestore, sendInviteToFirebase } from '../Services/CottageTripService';
import { Box, Tab, Tabs, createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import ListsTab from '../Tab Components/ListsTab';
import PostsTab from '../Tab Components/PostsTab';
import CarsTab from '../Tab Components/CarsTab';
import CottageInfoTab from '../Tab Components/CottageInfoTab';
import { ListItem } from '../Models/ListItem';
import { deleteItemsFromFirestore, getLists, uploadListItems } from '../Services/ListsService';
import { Car } from '../Models/Car';
import { deletePostFromFirestore, getPosts, uploadPostToFirebase } from '../Services/PostsService';
import { Post } from '../Models/Post';
import { Attendee } from '../Models/Attendee';
import AttendeesTab from '../Tab Components/AttendeesTab';
import { acceptPassengerRequestInFirestore, addCarToFirestore, createPassengerRequest, declinePassengerRequestInFirestore, deleteCarFromFirestore, getCars, leaveCarInFirestore, removeAllCarRequestAndPassengerStatus } from '../Services/CarsService';

function Cottage() {

    let {id} = useParams();

    //Navigation logic
    const navigate = useNavigate()
    const routeToHomePage = useCallback(() => {
        navigate('/')
    }, [navigate]);    

    //auth logic
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            
            if (!user) {
                routeToHomePage();
            }
            else {
                if (id) {
                    setLoggedInUserId(user.uid);
                    getCottageInfo(id)
                    .then((cottage) => {
                        setCottage(cottage);
                    })
                    .catch((error) => {
                        routeToHomePage();
                    });
                    getLists(id)
                    .then((list) => {
                        setList(list);
                    });
                    getCars(id)
                    .then((cars) => {
                        setIsLoadingCarButtonFlags(Array(cars.length).fill(false));
                        setCars(cars);
                    })
                    getPosts(id)
                    .then((posts) => {
                        setPosts(posts);
                    });
                    getAttendeesForCottage(id)
                    .then((attendees) => {
                        setAttendees(attendees);
                    })
                    getInvitedEmails(id)
                    .then((emails) => {
                        setInvites(emails);
                    })
                }
            }
        });
        return unsubscribe;
    }, [id, routeToHomePage]);

    //Tabs logic
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };
    const tabTheme = createTheme({
        palette: {
            primary: {
              main: "#1A3B0E",
            },
            secondary: {
              main: "#1A3B0E",
            },
          },
    })

    //Tab states
    const [loggedInUserId, setLoggedInUserId] = useState<string>('');
    const [cottage, setCottage] = useState<CottageInfo | null>(null);
    const [list, setList] = useState<ListItem[]>([]);
    const [cars, setCars] = useState<Car[] | null>(null);
    const [posts, setPosts] = useState<Post[] | null>(null);
    const [attendees, setAttendees] = useState<Attendee[]>([]);
    const [invites, setInvites] = useState<string[]>([]);
    const [isUploadingItems, setIsUploadingItems] = useState<boolean>(false);
    const [isLoadingCarButtonFlags, setIsLoadingCarButtonFlags] = useState<boolean[]>([]);

    //Tab functions
    const saveEditedCottageInfo = (info: CottageInfo) => {
        editCottageInfo(info)
        .then((info) => {
            setCottage(info);
        });
    };

    const canUserEditListTab = (): boolean => {
        if (cottage) {
            return (loggedInUserId === cottage.cottageOrganizer.firebaseUserID || cottage.isGroceriesCollaborative);
        }
        else {
            return false;
        }
    };

    const uploadItems = (items: ListItem[]): Promise<boolean> => {
        if (cottage) {
            setIsUploadingItems(true);
            return uploadListItems(items, cottage.cottageID)
            .then(_ => {
                return getLists(cottage.cottageID)
            })
            .then((retrievedList) => {
                setList(retrievedList);
                setIsUploadingItems(false);
                return false;
            });
        } else {
            return new Promise((resolve, reject) => {
                return false;
            });
        }
    };

    const deleteItems = (itemIdsToDelete: readonly string[]): Promise<boolean> => {
        if (cottage) {
            return deleteItemsFromFirestore(itemIdsToDelete, cottage.cottageID)
            .then(
                () => {
                    return getLists(cottage.cottageID)
                },
                (err) => {
                    
                }
            )
            .then((retrievedList) => {
                if (retrievedList) {
                    setList(retrievedList);
                }
                return false;
            });
        }
        else {
            return new Promise((resolve, reject) => {
                return false;
            });
        }
    };

    const deleteCar = (): Promise<void> => {

        if (cottage) {
            return deleteCarFromFirestore(cottage.cottageID, loggedInUserId)
            .then(() => {
                return getCars(cottage.cottageID)
                .then((cars) => {
                    setIsLoadingCarButtonFlags(Array(cars.length).fill(false));
                    setCars(cars);
                });
            });
        }
        else {
            return new Promise<void>((_resolve, reject) => {
                reject("No cottage");
            })
        }

    }

    const addCar = (numberOfSeats: number): Promise<void> => {

        if (cottage) {
            if (numberOfSeats > 0 && numberOfSeats <= 100) {
                return addCarToFirestore(cottage.cottageID, loggedInUserId, numberOfSeats)
                .then(() => {
                    return removeAllCarRequestAndPassengerStatus(cottage.cottageID, loggedInUserId)
                    .then(() => {
                        return getCars(cottage.cottageID)
                        .then((cars) => {
                            setIsLoadingCarButtonFlags(Array(cars.length).fill(false));
                            setCars(cars);
                        });
                    })
                })
            }
            else {
                return new Promise<void>((_resolve, reject) => {
                    reject("Number is kinda weird");
                })
            }
        }
        else {
            return new Promise<void>((_resolve, reject) => {
                reject("No cottage");
            })
        }

    }

    const createRequestInCar = (driverId: string): Promise<void> => {

        if (cottage && cars) {
            //get the car to create a request in
            const carToCreateRequestIn: Car | undefined = cars.find(function (car) {
                return car.driver.firebaseUserID === driverId
            });
            if (carToCreateRequestIn) {
                //check if we can create a request in the car
                const canCreateRequest = checkIfCanCreateRequest(carToCreateRequestIn);
                if (canCreateRequest) {
                    return createPassengerRequest(cottage.cottageID, driverId, loggedInUserId)
                    .then(() => {
                        return getCars(cottage.cottageID)
                        .then((cars) => {
                            setIsLoadingCarButtonFlags(Array(cars.length).fill(false));
                            setCars(cars);
                        });
                    })
                }
                else {
                    return new Promise<void>((_resolve, reject) => {
                        reject("Can't create request in car");
                    })
                }
            }
            else {
                return new Promise<void>((_resolve, reject) => {
                    reject("No car found with this id");
                })
            }
        }
        else {
            return new Promise<void>((_resolve, reject) => {
                reject("No cottage and/or cars list");
            })
        }

    }

    const checkIfCanCreateRequest = (car: Car): boolean => {
        if (cars) {
            if (cars.filter(car => car.driver.firebaseUserID === loggedInUserId).length > 0) {
                return false;
            }
            if (cars.filter(car => car.passengers.includes(loggedInUserId)).length > 0) {
                return false;
            }
            if (car.requests.includes(loggedInUserId)) {
                return false;
            }
            return true;
        }
        else {
            return false;
        }
    };

    const leaveCar = (driverId: string): Promise<void> => {
        if (cottage && cars) {
            return leaveCarInFirestore(cottage.cottageID, driverId, loggedInUserId)
            .then(() => {
                return getCars(cottage.cottageID)
                .then((cars) => {
                    setIsLoadingCarButtonFlags(Array(cars.length).fill(false));
                    setCars(cars);
                });
            })
        }
        else {
            return new Promise<void>((_resolve, reject) => {
                reject("No cottage and/or cars list");
            })
        }
    };

    const acceptPassengerRequest = (passengerId: string): Promise<void> => {
        if (cottage) {
            return acceptPassengerRequestInFirestore(cottage.cottageID, loggedInUserId, passengerId)
            .then(() => {
                return getCars(cottage.cottageID)
                .then((cars) => {
                    setIsLoadingCarButtonFlags(Array(cars.length).fill(false));
                    setCars(cars);
                });
            })
        }
        else {
            return new Promise<void>((_resolve, reject) => {
                reject("No cottage loaded");
            })
        }
    }

    const declinePassengerRequest = (passengerId: string): Promise<void> => {
        if (cottage) {
            return declinePassengerRequestInFirestore(cottage.cottageID, loggedInUserId, passengerId)
            .then(() => {
                return getCars(cottage.cottageID)
                .then((cars) => {
                    setIsLoadingCarButtonFlags(Array(cars.length).fill(false));
                    setCars(cars);
                });
            })
        }
        else {
            return new Promise<void>((_resolve, reject) => {
                reject("No cottage loaded");
            })
        }
    }

    const setCarTabButtonLoadingFlag = (index: number, flagValue: boolean) => {
        const nextFlags = isLoadingCarButtonFlags.map((flag, i) => {
            if (i === index) {
              return flagValue;
            } else {
              return false;
            }
          });
          setIsLoadingCarButtonFlags(nextFlags);
    }

    const removeAttendee = (attendee: Attendee): Promise<void> => {
        if (cottage) {
            return removeAttendeeFromFirestore(cottage.cottageID, attendee.firebaseUserID)
            .then(() => {
                return getAttendeesForCottage(cottage.cottageID)
            })
            .then((newAttendees) => {
                setAttendees(newAttendees);
            })
        }
        else {
            return new Promise<void>((_resolve, reject) => {
                reject("No cottage loaded");
            })
        }
    }

    const deleteInvite = (email: string): Promise<void> => {
        if (cottage) {
            return deleteInviteFromFirestore(cottage.cottageID, email)
            .then(() => {
                return getInvitedEmails(cottage.cottageID)
                .then((newEmails) => {
                    setInvites(newEmails);
                })
            })
        }
        else {
            return new Promise<void>((_resolve, reject) => {
                reject("No cottage loaded");
            })
        }
    }

    const sendInvite = (email: string): Promise<void> => {

        if (cottage) {
            return sendInviteToFirebase(cottage.cottageID, email)
            .then(() => {
                return getInvitedEmails(cottage.cottageID)
                .then((newEmails) => {
                    setInvites(newEmails);
                })
            })
            .catch((error: Error) => {
                throw(error);
            })
        }
        else {
            return new Promise<void>((_resolve, reject) => {
                reject("No cottage loaded");
            })
        }

    }

    const createPendingInvite = (email: string): Promise<void> => {
        
        if (cottage) {
            return createPendingInvites(cottage.cottageID, email)
            .then(() => {
                return getInvitedEmails(cottage.cottageID)
                .then((newEmails) => {
                    setInvites(newEmails);
                })
            })
        }
        else {
            return new Promise<void>((_resolve, reject) => {
                reject("No cottage loaded");
            })
        }

    }

    const uploadPost = (postText: string): Promise<void> => {

        if (cottage) {
            let currentDate: Date = new Date()
            return uploadPostToFirebase(cottage.cottageID, loggedInUserId, postText, currentDate)
            .then(() => {
                return getPosts(cottage.cottageID)
                .then((posts) => {
                    console.log("posts count: ", posts?.length)
                    setPosts(posts);
                });
            })
        }
        else {
            return new Promise<void>((_resolve, reject) => {
                reject("No cottage loaded");
            })
        }

    }

    const canUploadPost = (): boolean => {
        if (cottage) {
            if (cottage.isPostsCollaborative || cottage.cottageOrganizer.firebaseUserID === loggedInUserId) {
                return true;
            }
        }
        return false;
    }

    const deletePost = (postId: string): Promise<void> => {
        if (cottage) {
            return deletePostFromFirestore(cottage.cottageID, postId)
            .then(() => {
                return getPosts(cottage.cottageID)
                .then((posts) => {
                    console.log("posts count: ", posts?.length)
                    setPosts(posts);
                });
            })
        }
        else {
            return new Promise<void>((_resolve, reject) => {
                reject("No cottage loaded");
            })
        }
    }

    const deleteCottage = (): Promise<void> => {
        if (cottage) {
            return deleteCottageFromFirestore(cottage.cottageID);
        }
        else {
            return new Promise<void>((_resolve, reject) => {
                reject("No cottage loaded");
            })
        }
    }

    const leaveCottage =(): Promise<null> => {
        if (cottage) {
            return removeAttendeeFromFirestore(cottage.cottageID, loggedInUserId)
        }
        else {
            return new Promise<null>((_resolve, reject) => {
                reject("No cottage loaded");
            })
        }
    }

    return (
        <div>
            <ThemeProvider theme={tabTheme}>
                <div className='Tabs-layout'>
                    <Box display="flex" justifyContent="center" width="100%">
                        <Tabs 
                            value={tabValue} 
                            onChange={handleTabChange} 
                            centered 
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="scrollable auto tabs example"
                            textColor='primary'
                            indicatorColor='primary'>
                            <Tab label="Information"/>
                            <Tab label="Attendees"/>
                            <Tab label="Lists"/>
                            <Tab label="Cars"/>
                            <Tab label="Posts"/>
                        </Tabs>
                    </Box>
                </div>
            </ThemeProvider>
            <div className='Tab-container'>
                { tabValue === 0 && 
                    <div>
                        <CottageInfoTab 
                            info={cottage}
                            loggedInUserId={loggedInUserId}
                            editCottageInfo={saveEditedCottageInfo}
                            deleteCottage={deleteCottage} 
                            leaveCottage={leaveCottage}                       
                        />
                    </div>
                }
                { tabValue === 1 && 
                    <div>
                        <AttendeesTab
                            organizer={cottage?.cottageOrganizer}
                            attendees={attendees}
                            invites={invites}
                            canModifyAttendees={loggedInUserId === cottage?.cottageOrganizer.firebaseUserID}
                            removeAttendee={removeAttendee}
                            deleteInvite={deleteInvite}
                            sendInvite={sendInvite}
                            createPendingInvite={createPendingInvite}
                        />
                    </div>
                }
                { tabValue === 2 && 
                    <div>
                        <ListsTab 
                            list={list}
                            canEditList={canUserEditListTab()}
                            assignableAttendees={attendees}
                            uploadItems={uploadItems}
                            isUploadingItems={isUploadingItems}
                            deleteItems={deleteItems} 
                        />
                    </div>
                }
                { tabValue === 3 && 
                    <div>
                        <CarsTab 
                            cars={cars}
                            loggedInUserId={loggedInUserId}
                            deleteCar={deleteCar}
                            addCar={addCar}
                            createRequest={createRequestInCar}
                            leaveCar={leaveCar}
                            acceptPassengerRequest={acceptPassengerRequest}
                            rejectPassengerRequest={declinePassengerRequest}
                            isLoadingCarButtonFlags={isLoadingCarButtonFlags}
                            setCarTabButtonLoadingFlag={setCarTabButtonLoadingFlag}
                        />
                    </div>
                }
                { tabValue === 4 && 
                    <div>
                        <PostsTab 
                            posts={posts}
                            uploadPost={uploadPost}
                            canUploadPosts={canUploadPost()}
                            loggedInUserId={loggedInUserId} 
                            deletePost={deletePost}
                        />
                    </div>
                }
            </div>
        </div>
    )

}

export default Cottage;
