import './styles/InviteInboxTile.css'
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';

const InviteInboxTile = () => {

    return (
        <div className='Invite-container'>
            <p style={{ padding: '5px' }}>You have an invite!</p>
            <MarkunreadMailboxIcon style={{ padding: '5px' }} />
        </div>
    )

}

export default InviteInboxTile