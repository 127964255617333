import { FormEvent, useState } from 'react';
import './styles/AddListItems.css'
import { ListItem } from '../Models/ListItem';
import { Button, CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from '@mui/material';
import { TextFieldInput } from '../Shared/styles/TextField';
import { NumberFieldInput } from '../Shared/styles/NumberField';
import { Attendee } from '../Models/Attendee';
import { AddCircle, Padding, RemoveCircle } from '@mui/icons-material';
import { SelectFieldInput } from '../Shared/styles/SelectField';
import { firestoreAutoId } from '../Helpers/FirebaseIdGenerator';

interface addListItemsProps {
    assignableAttendees: Attendee[];
    uploadItems: (items: ListItem[]) => Promise<boolean>;
    isUploadingItems: boolean;
}

function AddListItems( { assignableAttendees, uploadItems, isUploadingItems } : addListItemsProps ) {

    //state
    const [itemsToAdd, setItemsToAdd] = useState<ListItem[]>([]);

    //Helper functions

    function getAttendeeById(id: String): Attendee | null {
        return assignableAttendees.find((attendee) => attendee.firebaseUserID == id) ?? null;
    }

    //State array mutations
    const addNewItem = () => {
        setItemsToAdd([
            ...itemsToAdd,
            { id: firestoreAutoId(), productName: "", quantity: 1, assignedTo: null }
        ]);
    };

    const deleteNewItem = (index: number) => {
        const nextItems = [...itemsToAdd];
        nextItems.splice(index, 1);
        setItemsToAdd(nextItems);
    };

    //Event handlers
    const handleItemNameChange = (itemName: string, index: number) => {
        const nextItems = itemsToAdd.map((c, i) => {
            if (i === index) {
                var newItem = c;
                newItem.productName = itemName;
                return newItem;
            } else {
                return c;
            }
        });
        setItemsToAdd(nextItems);
    };

    const handleItemQtyChange = (itemQty: string, index: number) => {
        const nextItems = itemsToAdd.map((c, i) => {
            if (i === index) {
                var newItem = c;
                newItem.quantity = +itemQty;
                return newItem;
            } else {
                return c;
            }
        });
        setItemsToAdd(nextItems);
    };

    const handleAssignedAttendeeChange = (attendee: Attendee | string, index: number) => {
        const nextItems = itemsToAdd.map((c, i) => {
            if (i === index) {
                var newItem = c;
                newItem.assignedTo = getAttendeeById(attendee as string);
                return newItem;
            } else {
                return c;
            }
        });
        setItemsToAdd(nextItems);
    };

    //form functions
    const addItemsButtonPressed = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        uploadItems(itemsToAdd)
        .then(() => {
            setItemsToAdd([]);
        });
    };

    return (
        <form onSubmit={addItemsButtonPressed}>
            <div className="container">
                <div className='title-container'>
                    Add Items
                </div>
                    { itemsToAdd.map((item, index) => {
                        return (
                            <Grid container direction={"row"} spacing={5} sx={{ paddingTop: '10px' }}>
                                <Grid item>
                                    <TextField 
                                        sx={TextFieldInput} 
                                        inputProps={{autoComplete: 'off'}} 
                                        InputLabelProps={{ style: { fontFamily: 'monospace', color: '#73925B' }}}
                                        className='CreateCottage-input' 
                                        label="Name" 
                                        onChange={(e) => handleItemNameChange(e.target.value, index)}
                                        value={itemsToAdd[index].productName}
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField 
                                        type='number'
                                        InputProps={{
                                            inputProps: { min: 1 }
                                        }}
                                        sx={NumberFieldInput} 
                                        inputProps={{autoComplete: 'off'}} 
                                        InputLabelProps={{ style: { fontFamily: 'monospace', color: '#73925B' }}}
                                        className='CreateCottage-input' 
                                        label="Quantity"
                                        onChange={(e) => handleItemQtyChange(e.target.value, index)}
                                        value={itemsToAdd[index].quantity}
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControl sx={{ width: 300 }}>
                                        <InputLabel 
                                            id="demo-multiple-name-label"
                                            sx={{
                                                color: 'rgb(115, 146, 91)',
                                                '&.Mui-focused': {
                                                    color: 'rgb(115, 146, 91)'
                                                }
                                            }}
                                        >
                                            Assign To
                                        </InputLabel>
                                        <Select
                                            sx={SelectFieldInput}
                                            onChange={(e) => handleAssignedAttendeeChange(e.target.value as Attendee, index)}
                                            input={<OutlinedInput label="Assign To" />}
                                            value={itemsToAdd[index].assignedTo}
                                            renderValue={(attendee) => attendee?.name ?? ""}
                                        >
                                        <MenuItem value={undefined}> 
                                            <em>Unassigned</em>
                                        </MenuItem>
                                        { assignableAttendees.map((attendee) => (
                                            <MenuItem
                                                key={attendee.firebaseUserID}
                                                value={attendee.firebaseUserID}
                                            >
                                            {attendee.name}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={() => deleteNewItem(index)}>
                                        <RemoveCircle sx={{ color: '#7d1e23' }}/>
                                    </IconButton>
                                </Grid>
                                { index !== itemsToAdd.length - 1 &&
                                    <Divider sx={{ marginTop: '20px', marginBottom: '20px', marginLeft: '40px', width: '100%' }}/>
                                }
                            </Grid>
                        )
                    })}
                <div className='add-button-container'>
                    <IconButton onClick={addNewItem}>
                        <AddCircle sx={{ fontSize: 40, color: '#1A3B0E' }}/>
                    </IconButton>
                </div>
                { itemsToAdd.length > 0 &&
                    <div>
                    {
                        isUploadingItems ?
                        <CircularProgress color='success' />
                        :
                        <Button
                            variant="contained"
                            sx={{
                                width: 300,
                                backgroundColor: '#1A3B0E',
                                '&:hover': {
                                    backgroundColor: '#102408'
                                }
                            }}
                            type='submit'>
                            Upload Items
                        </Button>
                    }
                    </div>
                }
            </div>
        </form>
    )

}

export default AddListItems;