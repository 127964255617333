import { Avatar, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, ListItemAvatar, TextField } from '@mui/material';
import { Attendee } from '../Models/Attendee';
import './styles/AttendeesTab.css'
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';

interface AttendeesTabProps {
    organizer: Attendee | undefined
    attendees: Attendee[]
    invites: string[]
    canModifyAttendees: boolean
    removeAttendee: (attendee: Attendee) => Promise<void>
    deleteInvite: (email: string) => Promise<void>
    sendInvite: (email: string) => Promise<void>
    createPendingInvite: (email: string) => Promise<void>
}

function AttendeesTab({ organizer, attendees, invites, canModifyAttendees, removeAttendee, deleteInvite, sendInvite, createPendingInvite } : AttendeesTabProps) {

    // State

    const [openInviteUserModal, setOpenInviteUserModal] = useState<boolean>(false);
    const [openSendUncreatedInviteModal, setOpenSendUncreatedInviteModal] = useState<boolean>(false);
    const [isSendingInvite, setIsSendingInvite] = useState<boolean>(false);
    const [isCreatingPendingInvite, setIsCreatingPendingInvite] = useState<boolean>(false);
    const [emailToCreatePendingInvite, setEmailToCreatePendingInvite] = useState<string | null>(null);
    const [confirmRemoveAttendeeDialogOpen, setConfirmRemoveAttendeeDialogOpen] = useState<boolean>(false);
    const [attendeeToConfirmRemove, setAttendeeToConfirmRemove] = useState<Attendee | null>(null);
    const [confirmDeleteInviteDialogOpen, setConfirmDeleteInviteDialogOpen] = useState<boolean>(false);
    const [invitedEmailToConfirmRemove, setInvitedEmailToConfirmRemove] = useState<string | null>(null);
    const [isRemovingInviteOrAttendee, setIsRemovingInviteOrAttendee] = useState<boolean>(false);

    // Event functions

    const removeAttendeePressed = (attendee: Attendee) => {
        setAttendeeToConfirmRemove(attendee);
        setConfirmRemoveAttendeeDialogOpen(true);
    }

    const didConfirmRemoveAttendee = () => {
        setIsRemovingInviteOrAttendee(true);
        if (attendeeToConfirmRemove) {
            removeAttendee(attendeeToConfirmRemove)
            .then(() => {
                setAttendeeToConfirmRemove(null);
                setIsRemovingInviteOrAttendee(false);
                setConfirmRemoveAttendeeDialogOpen(false);
            })
            .catch(() => {
                setAttendeeToConfirmRemove(null);
                setIsRemovingInviteOrAttendee(false);
                setConfirmRemoveAttendeeDialogOpen(false);
            })
        }
        else {
            setAttendeeToConfirmRemove(null);
            setIsRemovingInviteOrAttendee(false);
            setConfirmRemoveAttendeeDialogOpen(false);
        }
    }

    const didCancelRemoveAttendee = () => {
        setConfirmRemoveAttendeeDialogOpen(false);
        setAttendeeToConfirmRemove(null);
        setIsRemovingInviteOrAttendee(false);
    }

    const deleteInvitePressed = (email: string) => {
        setInvitedEmailToConfirmRemove(email);
        setConfirmDeleteInviteDialogOpen(true);
    }

    const didConfirmDeleteInvite = () => {
        setIsRemovingInviteOrAttendee(true);
        if (invitedEmailToConfirmRemove) {
            deleteInvite(invitedEmailToConfirmRemove)
            .then(() => {
                setInvitedEmailToConfirmRemove(null);
                setIsRemovingInviteOrAttendee(false);
                setConfirmDeleteInviteDialogOpen(false);
            })
            .catch(() => {
                setInvitedEmailToConfirmRemove(null);
                setIsRemovingInviteOrAttendee(false);
                setConfirmDeleteInviteDialogOpen(false);
            })
        }
    }

    const didCancelDeleteInvite = () => {
        setConfirmDeleteInviteDialogOpen(false);
        setInvitedEmailToConfirmRemove(null);
        setIsRemovingInviteOrAttendee(false);
    }

    const confirmSendPendingInvite = () => {
        setIsCreatingPendingInvite(true);
        
        if (emailToCreatePendingInvite) {
            createPendingInvite(emailToCreatePendingInvite)
            .then(() => {
                setOpenInviteUserModal(false);
                setOpenSendUncreatedInviteModal(false);
                setIsCreatingPendingInvite(false);
                setEmailToCreatePendingInvite(null);
            })
            .catch(() => {
                setEmailToCreatePendingInvite(null);
                setIsCreatingPendingInvite(false);
            })
        } else {
            setEmailToCreatePendingInvite(null);
            setIsCreatingPendingInvite(false);
        }
    }

    const cancelSendPendingInvite = () => {
        setEmailToCreatePendingInvite(null);
        setOpenSendUncreatedInviteModal(false);
    }

    // Functions

    const confirmSendInvite = (email: string) => {
        setIsSendingInvite(true);
        sendInvite(email)
        .then(() => {
            setOpenInviteUserModal(false);
            setIsSendingInvite(false);
        })
        .catch((error: Error) => {
            if (error.message === "notRegisteredError") {
                console.log("in not registered error block");
                confirmCreatePendingInvite(email);
            }
            else if (error.message === "userInCottage") {
                console.log("in already in cottage error block");
            }
            else {
                setOpenInviteUserModal(false);
            }
            setIsSendingInvite(false);
        })
    }
    const confirmCreatePendingInvite = (email: string) => {
        setEmailToCreatePendingInvite(email);
        setOpenSendUncreatedInviteModal(true);
    }

    return (
        <div className='Attendeestab-container'>
            <div className='Attendeestab-title'>
                Organizer
            </div>
            <div className='Attendee-list-cell-container'>
                <div style={{ paddingBottom: '10px' }}>
                    <ListItemAvatar sx={{ justifyContent: "center", display: "flex" }}>
                        <Avatar alt={organizer?.name} src="TODO-placeholder-tbd-once-pfp-implemented" />
                    </ListItemAvatar>
                </div>
                <div>
                    {organizer?.name}
                </div>
            </div>
            { attendees.length > 1 &&
            <div>
                <Divider sx={{ marginTop: '20px' }}/>
                <div className='Attendeestab-invites-title'>
                    Attendees
                </div>
            </div>
            }
            { attendees.filter(attendee => attendee?.firebaseUserID !== organizer?.firebaseUserID).map((attendee) => {
                return(
                    <div className='Attendee-list-cell-container'>
                        <div style={{ paddingBottom: '10px' }}>
                            <ListItemAvatar sx={{ justifyContent: "center", display: "flex" }}>
                                <Avatar alt={attendee?.name} src="TODO-placeholder-tbd-once-pfp-implemented" />
                            </ListItemAvatar>
                        </div>
                        <div>
                            {attendee?.name}
                        </div>
                        { canModifyAttendees &&
                            <div>
                                <IconButton 
                                    onClick={() => removeAttendeePressed(attendee)}
                                    disabled={isRemovingInviteOrAttendee}
                                >
                                    <DeleteIcon sx={{ color: '#7d1e23' }}/>
                                </IconButton>
                            </div>
                        }
                    </div>
                )
            })}
            { (invites?.length > 0 || canModifyAttendees) &&
                <div>
                    <Divider sx={{ marginTop: '20px' }}/>
                    <div className='Attendeestab-invites-title'>
                        Invites
                    </div>
                </div>
            }
            { canModifyAttendees &&
                <div className='Attendeestab-send-invite-container'>
                    <Button
                        variant="contained"
                        sx={{
                            width: 300,
                            backgroundColor: '#1A3B0E',
                            '&:hover': {
                                backgroundColor: '#102408'
                            }
                        }}
                        onClick={() => {setOpenInviteUserModal(true)}}>
                        Send Invite
                    </Button>
                </div>
            }
            {invites?.map((invitedEmail) => {
                return (
                    <div className='Attendee-list-cell-container'>
                        <div style={{ paddingBottom: '10px' }}>
                            <ListItemAvatar sx={{ justifyContent: "center", display: "flex" }}>
                                <Avatar alt={invitedEmail} src="TODO-placeholder-tbd-once-pfp-implemented" />
                            </ListItemAvatar>
                        </div>
                        <div>
                            {invitedEmail}
                        </div>
                        { canModifyAttendees &&
                            <div>
                                <IconButton 
                                    onClick={() => deleteInvitePressed(invitedEmail)}
                                    disabled={isRemovingInviteOrAttendee}
                                >
                                    <DeleteIcon sx={{ color: '#7d1e23' }}/>
                                </IconButton>
                            </div>
                        }
                    </div>
                )
            })}
            <Dialog
                open={openInviteUserModal}
                onClose={() => setOpenInviteUserModal(false)}
                fullWidth={true}
                maxWidth={'md'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ zIndex:'10000000000000000000' }}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                      event.preventDefault();
                      const formData = new FormData(event.currentTarget);
                      const formJson = Object.fromEntries((formData as any).entries());
                      const email = formJson.email;
                      confirmSendInvite(email);
                    },
                  }}
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Send Invite"}
                    </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                    />
                    { isSendingInvite &&
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <CircularProgress color='success' />
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        disabled={isSendingInvite}
                    >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openSendUncreatedInviteModal}
                onClose={() => {setOpenSendUncreatedInviteModal(false)}}
                sx={{ zIndex:'10000000000000000001' }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"User has not registered for Cottage Buddy"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div>
                            The user has not registered for Cottage Buddy. Would you like to send them an invite to register and accept this cottage invite?
                        </div>
                        { isCreatingPendingInvite ?
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <CircularProgress color='success' />
                            </div>
                            :
                            <div style={{ marginTop: '10px', width: '100%', textAlign: 'center' }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#7d1e23',
                                        '&:hover': {
                                            backgroundColor: '#571518'
                                        }, 
                                        display: 'inline-block', 
                                        margin: '0 auto',
                                        width: '40%'
                                    }}
                                    onClick={() => {cancelSendPendingInvite()}}>
                                    Cancel
                                </Button>
                                <div style={{ display: 'inline-block', width: '2%' }}/>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#1A3B0E',
                                        '&:hover': {
                                            backgroundColor: '#102408'
                                        }, 
                                        display: 'inline-block', 
                                        margin: '0 auto',
                                        width: '40%'
                                    }}
                                    onClick={() => {confirmSendPendingInvite()}}>
                                    Invite
                                </Button>
                            </div>
                        }
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog
                open={confirmRemoveAttendeeDialogOpen}
                onClose={didCancelRemoveAttendee}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ zIndex:'10000000000000000000' }}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Remove attendee?"}
                </DialogTitle>
                <DialogContent>
                    { isRemovingInviteOrAttendee ? 
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <CircularProgress color='success' />
                        </div>
                        :
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you'd like remove {attendeeToConfirmRemove?.name} from this cottage?
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={didCancelRemoveAttendee}>Cancel</Button>
                    <Button onClick={didConfirmRemoveAttendee} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={confirmDeleteInviteDialogOpen}
                onClose={didCancelDeleteInvite}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ zIndex:'10000000000000000000' }}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Invite?"}
                </DialogTitle>
                <DialogContent>
                    { isRemovingInviteOrAttendee ? 
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <CircularProgress color='success' />
                        </div>
                        :
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you'd like to remove the invite sent to {invitedEmailToConfirmRemove}?
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={didCancelDeleteInvite}>Cancel</Button>
                    <Button onClick={didConfirmDeleteInvite} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}

export default AttendeesTab;