 import './styles/ContactUs.css'

 function ContactUS() {
    
    return (
        <div className='Contactus-container'>
            <h1 className="Contactus-title">
                Contact Us
            </h1>
            <div className='Contactus-text-container'>
                This website/application is a passion project!
            </div>
            <div className='Contactus-text-container'>
                Have any suggestions or bugs to report? I'd love to hear from you.
            </div>
            <div className='Contactus-text-container'>
                Email the developer at:
            </div>
            <div className='Contactus-text-container'>
                <a href="mailto:philip@cottagebuddy.app">philip@cottagebuddy.app</a>
            </div>
        </div>
    );

 }

 export default ContactUS;