import { useMemo, useState } from 'react';
import { ListItem } from '../Models/ListItem';
import EmptyTab from './EmptyTab';
import './styles/ListsTab.css'
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Theme } from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import { Attendee } from '../Models/Attendee';
import AddListItems from '../Components/AddListItems';
import EnhancedTableToolbar from '../Components/EnhancedTableToolbar';

interface listProps {
    list: ListItem[];
    canEditList: boolean;
    assignableAttendees: Attendee[];
    uploadItems: (items: ListItem[]) => Promise<boolean>;
    isUploadingItems: boolean;
    deleteItems: (itemIds: readonly string[]) => Promise<boolean>;
}

function ListsTab( { list, canEditList, assignableAttendees, uploadItems, isUploadingItems, deleteItems   } : listProps ) {

    //Properties
    var rowCount: number = list?.length ?? 0;
    type Order = 'asc' | 'desc';

    //State
    const [selected, setSelected] = useState<readonly string[]>([]);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof ListItem>('productName');
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

    //Event handlers
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            if (list) {
                const newSelected = list.map((n) => n.id);
                setSelected(newSelected);
                return;
            }
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly string[] = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
        setSelected(newSelected);
      };

    const deleteItemsButtonPressed = () => {
        setOpenDeleteConfirm(true);
    };
    const handleClickConfirmDeleteItems = () => {
        setOpenDeleteConfirm(false);
        deleteItems(selected)
        .then(() => {
            setSelected([]);
        })
      };
    
      const handleClickCancelDeleteItems = () => {
        setOpenDeleteConfirm(false);
      };

    //Sorting functions
    const createNameSortHandler =
    (property: keyof ListItem) => (event: React.MouseEvent<unknown>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {

        if (orderBy === 'assignedTo') {
            let attendee1: Attendee | null = b[orderBy] as Attendee | null;
            let attendee2: Attendee | null = a[orderBy] as Attendee | null;

            let attendee1Name: string = attendee1?.name ?? "";
            let attendee2Name: string = attendee2?.name ?? "";

            if (attendee1Name < attendee2Name) {
              return -1;
            }
            if (attendee1Name > attendee2Name) {
              return 1;
            }
            return 0;
        }

        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }

      function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key,
      ): (
        a: { [key in Key]: number | string | Attendee | null },
        b: { [key in Key]: number | string | Attendee | null },
      ) => number {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }

    const visibleRows = useMemo(
        () => 
        [...list]
            .sort(getComparator(order, orderBy)),
        [order, orderBy, list]
    )

    return (
        <div className='Liststab-container'>
            <div className='Liststab-title'>
                Lists
            </div>
            {
                list ?
                <div>
                    { list.length > 0 ?
                        <div className='Liststab-table-container'>
                            <EnhancedTableToolbar numSelected={selected.length} deleteItemsButtonPressed={ deleteItemsButtonPressed } canEditList={canEditList} />
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: '#A7D489', font: 'monospace' }}>
                                            { canEditList &&
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        //color="primary"
                                                        indeterminate={selected.length > 0 && selected.length < rowCount}
                                                        checked={rowCount > 0 && selected.length === rowCount}
                                                        onChange={handleSelectAllClick}
                                                    />
                                                </TableCell>
                                            }
                                            <TableCell 
                                                sx={{ fontFamily: 'monospace', fontWeight: 'bold' }}
                                                key='productName'
                                                sortDirection={orderBy === 'productName' ? order : false}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === 'productName'}
                                                    direction={orderBy === 'productName' ? order : 'asc'}
                                                    onClick={createNameSortHandler('productName')}
                                                >
                                                    Item
                                                    {orderBy === 'productName' ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell 
                                                sx={{ fontFamily: 'monospace', fontWeight: 'bold' }} align="right"
                                                key='quantity'
                                                sortDirection={orderBy === 'quantity' ? order : false}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === 'quantity'}
                                                    direction={orderBy === 'quantity' ? order : 'asc'}
                                                    onClick={createNameSortHandler('quantity')}
                                                >
                                                    Quantity
                                                    {orderBy === 'quantity' ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell 
                                                sx={{ fontFamily: 'monospace', fontWeight: 'bold' }} align="right"
                                                key='assignedTo'
                                                sortDirection={orderBy === 'assignedTo' ? order : false}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === 'assignedTo'}
                                                    direction={orderBy === 'assignedTo' ? order : 'asc'}
                                                    onClick={createNameSortHandler('assignedTo')}
                                                >
                                                    Assigned To
                                                    {orderBy === 'assignedTo' ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {visibleRows.map((item, index) => {
                                            const isItemSelected = selected.includes(item.id);
                                            return (
                                                <TableRow 
                                                    key={item.id} 
                                                    sx={{ 
                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                        backgroundColor: '#A7D489',
                                                        '&.MuiTableRow-root': {
                                                            '&:hover': {
                                                                backgroundColor: '#A7D489',
                                                            },
                                                        },
                                                        '&.Mui-selected': {
                                                            backgroundColor: '#A7D489'
                                                        },
                                                        '&.MuiTableRow-hover': {
                                                            '&:hover': {
                                                                backgroundColor: '#A7D489',
                                                            },
                                                        }
                                                        }}
                                                    hover
                                                    onClick={(event) => handleClick(event, item.id)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    selected={isItemSelected}
                                                >
                                                    { canEditList &&
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                //'aria-labelledby': labelId,
                                                                }}
                                                            />
                                                        </TableCell>
                                                    }
                                                    <TableCell sx={{ fontFamily: 'monospace' }} component="th" scope="row">
                                                        {item.productName}
                                                    </TableCell>
                                                    <TableCell sx={{ fontFamily: 'monospace' }} align="right">{item.quantity.toString()}</TableCell>
                                                    <TableCell sx={{ fontFamily: 'monospace' }} align="right">{item.assignedTo?.name}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        :
                        <EmptyTab/>
                    }
                    { canEditList &&
                        <div>
                            <Divider style={{ marginTop: '20px' }}/>
                            <div className='Liststab-create-items-section'>
                                <AddListItems assignableAttendees={assignableAttendees} uploadItems={uploadItems} isUploadingItems={isUploadingItems}/>
                            </div>
                        </div>
                    }
                </div>
                :
                <div>
                    <CircularProgress color='success' />
                </div>
            }
            <div>
                <Dialog
                    open={openDeleteConfirm}
                    onClose={handleClickCancelDeleteItems}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{ zIndex:'10000000000000000000' }}
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete items?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you'd like to delete these items?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={handleClickCancelDeleteItems}
                            sx={{ color: '#1A3B0E' }}
                        >Cancel</Button>
                        <Button 
                            onClick={handleClickConfirmDeleteItems}
                            sx={{ color: '#1A3B0E' }}
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )

}

export default ListsTab;