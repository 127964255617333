import { Attendee } from "./Attendee"

export class Car {

    driver: Attendee
    numberOfSeats: Number
    passengers: string[]
    requests : string[]

    constructor(
        driver: Attendee,
        numberOfSeats: Number,
        passengers: string[],
        requests: string[]
    ) {
        this.driver = driver;
        this.numberOfSeats = numberOfSeats;
        this.passengers = passengers;
        this.requests = requests;
    }

}

export class CarRequest {

    requester: Attendee
    
    constructor(
        requester: Attendee
    ) {
        this.requester = requester
    }

}