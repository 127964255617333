import { useEffect, useState } from 'react';

export enum ScreenSize {
    xs = 0,
    sm = 576,
    md = 768,
    lg = 992,
    xl = 1200,
    xxl = 1400
}

const useScreenSize = () => {

    const [width, setWidth] = useState<number>(window.innerWidth);
    const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    if (width <= ScreenSize.sm) {
        return ScreenSize.xs
    }
    else if (width <= ScreenSize.md) {
        return ScreenSize.sm
    }
    else if (width <= ScreenSize.lg) {
        return ScreenSize.md
    }
    else if (width <= ScreenSize.xl) {
        return ScreenSize.lg
    }
    else if (width <= ScreenSize.xxl) {
        return ScreenSize.xl
    }
    else if (width > ScreenSize.xxl) {
        return ScreenSize.xxl
    }    
    else
        return ScreenSize.md

}

export default useScreenSize
