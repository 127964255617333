import { Attendee } from "./Attendee";

export class Post {

    id: string;
    poster: Attendee;
    message: string;
    likers: string[];
    datePosted: Date;

    constructor(
        id: string,
        poster: Attendee,
        message: string,
        likers: string[],
        datePosted: Date,
    ) {
        this.id = id;
        this.poster = poster;
        this.message = message;
        this.likers = likers;
        this.datePosted = datePosted;
    }

}